import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Excel from "exceljs";
import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import type {
  ApplicantExportDataFragment,
  ApplicantExportQuestionFragment,
} from "generated/graphql";
import { useGetApplicantExportDataLazyQuery } from "generated/graphql";
import useCurrentCampaign from "../../hooks/campaign/useCurrentCampaign";
import Iconify from "../Iconify";
import { findCvURL, shouldDisplayQuestion } from "./shared/utils";

const supportedFileTypes = ["xlsx", "csv"] as const;
type SupportedFileType = (typeof supportedFileTypes)[number];

const ExportFileSchema = Yup.object().shape({
  fileName: Yup.string().required("Bitte geben Sie einen Dateinamen ein."),
  fileType: Yup.mixed<SupportedFileType>()
    .oneOf(supportedFileTypes, "Ungültiges Dateiformat.")
    .required("Bitte wählen Sie ein Dateiformat aus."),
});
type ExportFileValues = Yup.InferType<typeof ExportFileSchema>;

const COLUMNS = [
  {
    key: "id",
    header: "ID",
  },
  {
    key: "first_name",
    header: "Vorname",
  },
  {
    key: "last_name",
    header: "Nachname",
  },
  {
    key: "qualified",
    header: "Qualifiziert",
  },
  {
    key: "email",
    header: "Email",
  },
  {
    key: "phone",
    header: "Telefon",
  },
  {
    key: "converted_at",
    header: "Beworben um",
  },
  {
    key: "cv_url",
    header: "Lebenslauf",
  },
] as const;

type ColumnId = (typeof COLUMNS)[number]["key"];
type BaseRow = Record<ColumnId, unknown>;

export default function ApplicantExportButton() {
  const [isOpened, setIsOpened] = useState(false);

  function handleClick() {
    setIsOpened(true);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={<Iconify icon="heroicons:document-arrow-up" />}
        variant="outlined"
      >
        Exportieren
      </Button>
      <ExportDialog isOpened={isOpened} setIsOpened={setIsOpened} />
    </>
  );
}

function ExportDialog({
  isOpened,
  setIsOpened,
}: {
  readonly isOpened: boolean;
  readonly setIsOpened: (value: boolean) => void;
}) {
  function handleClose() {
    setIsOpened(false);
  }

  const { campaign } = useCurrentCampaign();
  const { enqueueSnackbar } = useSnackbar();
  const [queryApplicantExportData] = useGetApplicantExportDataLazyQuery();

  async function getApplicantExportData(): Promise<{
    applicants: ApplicantExportDataFragment[];
    questions: ApplicantExportQuestionFragment[];
    organization_id: string;
  }> {
    const campaignId = campaign!.id!;
    const { data } = await queryApplicantExportData({
      variables: {
        campaign_id: campaignId,
      },
    });
    return {
      applicants: data!.contact,
      questions: data!.campaign_by_pk!.questions,
      organization_id: data!.campaign_by_pk!.organization.id
    };
  }

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} open={isOpened}>
      <Formik
        initialValues={{
          fileName: "",
          fileType: "xlsx" satisfies SupportedFileType as SupportedFileType,
        }}
        onSubmit={async (values) => {
          try {
            const { applicants, questions, organization_id } = await getApplicantExportData();
            const buffer = await exportApplicantsToBuffer(
              applicants,
              questions,
              organization_id,
              values.fileType,
            );

            downloadExcelBuffer(values, buffer);
            handleClose();
          } catch (e) {
            console.error(`Error exporting file: ${e}`);
            enqueueSnackbar("Fehler beim Exportieren der Datei", {
              variant: "error",
            });
          }
        }}
        validationSchema={ExportFileSchema}
      >
        {(formik) => <ExportDialogForm formik={formik} onClose={handleClose} />}
      </Formik>
    </Dialog>
  );
}

function ExportDialogForm({
  formik: {
    handleSubmit,
    getFieldProps,
    values,
    errors,
    touched,
    isValid,
    setFieldValue,
    isSubmitting,
  },
  onClose,
}: {
  readonly formik: FormikProps<ExportFileValues>;
  readonly onClose: () => void;
}) {
  const { campaign } = useCurrentCampaign();

  useEffect(() => {
    const campaignName = campaign?.name;
    if (!touched.fileName && campaignName != null) {
      setFieldValue("fileName", campaignName, false);
    }
  }, [campaign?.name]);

  return (
    <Form onSubmit={handleSubmit}>
      <DialogTitle>Exportieren</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <DialogContentText>
            Bitte geben Sie einen Dateinamen ein, mit dem die Datei exportiert
            werden soll.
          </DialogContentText>
          <Stack direction="row" gap={1}>
            <TextField
              {...getFieldProps("fileName")}
              InputProps={{
                endAdornment: getFileExtension(values.fileType),
              }}
              error={Boolean(errors.fileName && touched.fileName)}
              fullWidth
              helperText={errors.fileName}
              placeholder="Dateiname"
              sx={{ flex: 1 }}
              value={values.fileName}
            />
            <TextField
              {...getFieldProps("fileType")}
              SelectProps={{
                native: true,
              }}
              error={Boolean(errors.fileType && touched.fileType)}
              helperText={errors.fileType}
              placeholder="Dateiformat"
              select
            >
              {supportedFileTypes.map((option) => (
                <option key={option} value={option}>
                  {getDisplayName(option)}
                </option>
              ))}
            </TextField>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Abbrechen
        </Button>
        <LoadingButton
          disabled={isSubmitting || campaign == null || !isValid}
          loading={isSubmitting}
          type="submit"
        >
          Exportieren
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

function exportApplicantsToBuffer(
  applicants: ApplicantExportDataFragment[],
  questions: ApplicantExportQuestionFragment[],
  organization_id: string,
  fileType: SupportedFileType,
) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Bewerber");
  const columns: Partial<Excel.Column>[] = [...COLUMNS];

  // Add new column if the organization is 'huk'
  if (organization_id === 'huk') {
    columns.push({
      key: 'campaign_name',
      header: 'Kampagne',
    });
  }
  for (const question of questions) {
    if (!shouldDisplayQuestion(question.title)) {
      continue;
    }
    columns.push({
      key: question.id,
      header: question.title,
    });
  }
  worksheet.columns = columns;
  for (const applicant of applicants) {
    const cvUrl = findCvURL(applicant.answers);

    const baseRow: BaseRow = {
      id: applicant.id,
      first_name: applicant.first_name,
      last_name: applicant.last_name,
      qualified: applicant.qualified ? "Ja" : "Nein",
      email: applicant.email_address,
      phone: applicant.phone_number,
      converted_at: applicant.converted_at != null ? new Date(applicant.converted_at) : "",
      cv_url: cvUrl != null ? { text: "Lebenslauf", hyperlink: cvUrl } : "",
      ...(organization_id === 'huk' && { campaign_name: applicant.origin_campaign?.name || '' }), // Add campaign name if organization is 'huk'
    };
    const answerRow: Record<string, unknown> = {};
    for (const answer of applicant.answers) {
      const question = answer.question;
      if (question == null) {
        console.warn("Answer without question", answer);
        continue;
      }
      if (!shouldDisplayQuestion(question.title)) {
        continue;
      }
      answerRow[question.id] = answer.value;
    }
    worksheet.addRow({ ...baseRow, ...answerRow });
  }

  switch (fileType) {
    case "xlsx":
      return workbook.xlsx.writeBuffer();
    case "csv":
      return workbook.csv.writeBuffer();
  }
}

function downloadExcelBuffer(values: ExportFileValues, buffer: Excel.Buffer) {
  const fileExtension = getFileExtension(values.fileType);
  const mimeType = getMimeType(values.fileType);
  // stolen from https://stackoverflow.com/questions/48760815/export-to-csv-button-in-react-table
  const blob = new Blob([buffer], {
    type: mimeType,
  });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  if (link.download !== undefined) {
    link.setAttribute("href", url);
    link.setAttribute("download", values.fileName + fileExtension);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(url);
  }
  URL.revokeObjectURL(url);
}

function getFileExtension(fileType: SupportedFileType) {
  return "." + fileType;
}

function getDisplayName(fileType: SupportedFileType) {
  switch (fileType) {
    case "xlsx":
      return "Excel";
    case "csv":
      return "CSV";
  }
}

function getMimeType(fileType: SupportedFileType) {
  switch (fileType) {
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "csv":
      return "text/csv";
  }
}
