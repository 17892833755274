import { Grid, Stack } from "@mui/material";
import { CampaignCard } from "components/campaigns/CampaignCard";
import type { HukGsDetailsFragment } from "generated/graphql";
import type { PLATFORM } from "hooks/campaign/getCampaignKPIs";
import { getHukGsKPIs } from "hooks/campaign/getHukGsKPIs";
import { useState } from "react";
import InsightsSection from "../InsightsSection";
import HukApplicationInsights from "./HukApplicationInsights";
import { ExternalHukKpiCard } from "./HukKpiCards";
import { HukQuestionInsightsSection } from "./HukQuestionInsightsSection";

export function GsInsightsDisplay({
  gs,
}: {
  readonly gs: HukGsDetailsFragment;
}) {
  const [selectedPlatform, setSelectedPlatform] = useState<PLATFORM>("GESAMT");

  const [selectedContactPlatform, setSelectedContactPlatform] =
    useState<PLATFORM>("GESAMT");

  const campaigns = gs.gs_campaigns;
  const { kpis, nonZeroKPIPlatforms, totalLeadsMap, qualifiedLeadsMap } =
    getHukGsKPIs(gs, selectedPlatform);

  return (
    <Stack alignItems="stretch" spacing={7} sx={{ mb: 5, mt: 5 }}>
      {kpis[3] !== undefined && <ExternalHukKpiCard kpis={kpis} />}
      {totalLeadsMap.size > 0 ? (
        <InsightsSection title="Bewerbungen">
          <HukApplicationInsights
            gs={gs}
            qualifiedLeadsMap={qualifiedLeadsMap}
            selectedTab={selectedContactPlatform}
            setSelectedTab={setSelectedContactPlatform}
            totalLeadsMap={totalLeadsMap}
          />
        </InsightsSection>
      ) : null}
      <HukQuestionInsightsSection campaigns={campaigns} />
      <InsightsSection title="Kampagnen">
        <Grid alignItems="stretch" container justifyItems="stretch" spacing={3}>
          {[...campaigns]
            ?.sort((a, b) => {
              const isPriorityA =
                a.campaign?.status === "DRAFT" ||
                a.campaign?.status === "ACTIVE";
              const isPriorityB =
                b.campaign?.status === "DRAFT" ||
                b.campaign?.status === "ACTIVE";

              if (isPriorityA !== isPriorityB) {
                return isPriorityA ? -1 : 1;
              }

              const dateA = a.campaign?.start || "";
              const dateB = b.campaign?.start || "";
              return dateB.localeCompare(dateA);
            })
            .map((campaign, index) => {
              if (!campaign.campaign) return null;
              return (
                <Grid
                  display="flex"
                  item
                  key={campaign.campaign?.id ?? `campaign ${index}`}
                  lg={6}
                  sm={12}
                  xl={4}
                >
                  <CampaignCard campaign={campaign.campaign} />
                </Grid>
              );
            })}
        </Grid>
      </InsightsSection>
    </Stack>
  );
}
