import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "lib/utils";
import * as React from "react";

const buttonVariants = cva(
  "inline-flex items-center gap-1 justify-center whitespace-nowrap rounded-md text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:pointer-events-none disabled:opacity-50 duration-300",
  {
    variants: {
      variant: {
        default: "bg-gray-200 text-gray-600 hover:bg-gray-300",
        destructive: "hover:bg-danger-active bg-danger text-white",
        outline:
          "border border-gray-300 bg-white text-gray-600 hover:bg-gray-100",
        secondary:
          "bg-primary-light text-primary-active hover:bg-primary hover:text-white",
        ghost: "hover:bg-gray-200/50",
        link: "text-primary underline-offset-4 hover:underline",
        active: "bg-primary text-white hover:bg-primary-active",
        card: "bg-white text-gray-700 hover:bg-gray-200 shadow-[0_3px_4px_0_rgba(0,0,0,0.03)] border border-gray-200 hover:border-white",
        activeCard:
          "bg-primary text-white hover:bg-primary-active hover:border-white/50 border-white/30 border shadow-[0_3px_4px_0_rgba(0,0,0,0.03)]",
        successCard:
          "bg-success-active text-white hover:bg-success-active/60 border-white/30 border shadow-[0_3px_4px_0_rgba(0,0,0,0.03)]",
      },
      size: {
        default: "px-4 py-2 h-9",
        sm: "rounded-md px-3 py-1.5 text-xs",
        lg: "rounded-md px-8 py-4",
        icon: "size-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  readonly asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants, type ButtonProps };
