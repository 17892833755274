import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

const TEMPORARY_HARDCODED_ADMIN_PETER_KIRCHNER_PROFILE_PICTURE =
  "https://ca.slack-edge.com/T01NWJDTZUP-U01Q16VM08G-4fbbb20166cc-512";

export function useUser() {
  const userContext = useContext(UserContext);

  if (!userContext.user) return userContext;

  const isUserAdminPeterKirchner = userContext.user.email === "pk@everbay.de";

  return {
    ...userContext,
    user: {
      ...userContext.user,
      picture: isUserAdminPeterKirchner
        ? TEMPORARY_HARDCODED_ADMIN_PETER_KIRCHNER_PROFILE_PICTURE
        : userContext.user.picture,
    },
  };
}
