import { useLogto } from "@logto/react";
import type { ReactNode } from "react";
import { useContext, useEffect } from "react";
import ImpersonationContext from "../contexts/ImpersonationContext";
import type { User } from "../contexts/UserContext";
import { useLazyBackendRequest } from "../hooks/useBackendRequest";
import { useUser } from "../hooks/useUser";

type AuthGuardProps = {
  readonly children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, error, signIn } = useLogto();
  const { organization } = useContext(ImpersonationContext);
  const { user, setUser } = useUser();
  const { request: fetchUser } = useLazyBackendRequest<User>(`/users/me`);

  async function populateUserContext() {
    const response = await fetchUser();
    if (response.data != null) {
      setUser(response.data);
    } else {
      console.error("Could not fetch user data");
    }
  }

  useEffect(() => {
    if (isAuthenticated && user == null) {
      populateUserContext();
    }
  }, [isAuthenticated, setUser]);

  useEffect(() => {
    if (user != null) {
      populateUserContext();
    }
  }, [organization]);

  useEffect(() => {
    if (!isAuthenticated && !error) {
      // Store the current path for redirect after login
      localStorage.setItem("redirectUrl", location.pathname);
      // Directly trigger Logto sign in
      signIn(`${window.location.protocol}//${window.location.host}/callback`);
    }
  }, [isAuthenticated, error]);

  // Show nothing while redirecting to login
  if (!isAuthenticated || error) {
    return null;
  }

  return <>{children}</>;
}
