import { useHandleSignInCallback, useLogto } from "@logto/react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
});

export function LoginCallback() {
  const navigate = useNavigate();
  const { signIn } = useLogto();

  const { isLoading, error, isAuthenticated } = useHandleSignInCallback(() => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    navigate(redirectUrl || "/", { replace: true });
  });

  async function loginClicked() {
    await signIn(
      `${window.location.protocol}//${window.location.host}/callback`,
    );
  }

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = localStorage.getItem("redirectUrl");
      navigate(redirectUrl || "/", { replace: true });
    }
  }, [isAuthenticated]);

  if (error !== undefined || !isAuthenticated) {
    return (
      <PageWrapper>
        <Card sx={{ p: 4 }}>
          <Stack spacing={2}>
            <Typography align="center" fontWeight="bold" variant="h5">
              Bei der Anmeldung ist ein Fehler aufgetreten.
            </Typography>
            {error ? (
              <Typography fontFamily="monospace">{error?.message}</Typography>
            ) : null}
            <Button onClick={loginClicked} size="large" variant="contained">
              Erneut versuchen
            </Button>
          </Stack>
        </Card>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Card sx={{ p: 4 }}>
        <CircularProgress />
        <Typography>Sie werden angemeldet...</Typography>
        <Link href="#" onClick={loginClicked}>
          Login neu starten
        </Link>
      </Card>
    </PageWrapper>
  );
}
