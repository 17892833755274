import AuthGuard from "auth/AuthGuard";
import { LoginCallback } from "auth/LoginCallback";
import { PermissionGuard, RoleGuard } from "auth/PermissionGuard";
import { FeatureFlagGuard } from "components/FeatureFlagGuard";
import Loading from "components/Loading";
import { LogoutIdleTimer } from "components/LogoutIdleTimer";
import { useBackendHealthService } from "hooks/useBackendHealthService";
import useFeatureFlag from "hooks/useFeatureFlag";
import HukCampaignsPage from "pages/HukCampaignsPage";
import GsDetailPage from "pages/campaign/GsDetailPage";
import type { ElementType } from "react";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import AppBarLayout from "../layouts/AppBarLayout";

function Loadable(Component: ElementType) {
  return function (props: any) {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const RedesignedDashboardPage = Loadable(
  lazy(() =>
    import("pages/redesign/DashboardPage").then((module) => ({
      default: module.DashboardPage,
    })),
  ),
);
const RedesignedCampaignsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CampaignsPage").then((module) => ({
      default: module.CampaignsPage,
    })),
  ),
);
const RedesignedCreateOrEditCampaignPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CreateOrEditCampaignPage").then(
      (module) => ({
        default: module.CreateOrEditCampaignPage,
      }),
    ),
  ),
);
const RedesignedCampaignDetailsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CampaignDetailsPage").then((module) => ({
      default: module.CampaignDetailsPage,
    })),
  ),
);
const RedesignedCampaignApplicationsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CampaignApplicationsPage").then(
      (module) => ({
        default: module.CampaignApplicationsPage,
      }),
    ),
  ),
);
const RedesignedCampaignOrdersPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CampaignOrdersPage").then((module) => ({
      default: module.CampaignOrdersPage,
    })),
  ),
);
const RedesignedCampaignOrderDetailsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/CampaignOrderDetailsPage").then(
      (module) => ({
        default: module.CampaignOrderDetailsPage,
      }),
    ),
  ),
);
const RedesignedCustomersPage = Loadable(
  lazy(() =>
    import("pages/redesign/customers/CustomersPage").then((module) => ({
      default: module.CustomersPage,
    })),
  ),
);
const RedesignedCustomerDetailsPage = Loadable(
  lazy(() =>
    import("pages/redesign/customers/CustomerDetailsPage").then((module) => ({
      default: module.CustomerDetailsPage,
    })),
  ),
);
const RedesignedProfilePage = Loadable(
  lazy(() =>
    import("pages/redesign/ProfilePage").then((module) => ({
      default: module.ProfilePage,
    })),
  ),
);

const RedesignedHukCampaignsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/HukCampaignsPage").then((module) => ({
      default: module.HukCampaignsPage,
    })),
  ),
);
const RedesignedGSDetailsPage = Loadable(
  lazy(() =>
    import("pages/redesign/campaigns/GSDetailsPage").then((module) => ({
      default: module.GSDetailsPage,
    })),
  ),
);
const RedesignedQuotationPage = Loadable(
  lazy(() =>
    import("pages/redesign/QuotationPage").then((module) => ({
      default: module.QuotationPage,
    })),
  ),
);

const MaintenancePage = Loadable(
  lazy(() =>
    import("pages/MaintenancePage").then((module) => ({
      default: module.MaintenancePage,
    })),
  ),
);

const Campaigns = Loadable(lazy(() => import("pages/CampaignsPage")));
const CreateAndEditCampaignPage = Loadable(
  lazy(() => import("pages/campaign/CreateAndEditCampaignPage")),
);
const Customers = Loadable(
  lazy(() => import("pages/admin/customers/CustomersPage")),
);
const CustomerDetails = Loadable(
  lazy(() => import("pages/admin/customers/CustomerDetailsPage")),
);
const NotFound = Loadable(lazy(() => import("pages/NotFound")));

const Destinations = Loadable(
  lazy(() => import("../pages/admin/zipcode_routing/DestinationsPage")),
);

const CampaignDetailPage = Loadable(
  lazy(() => import("pages/campaign/CampaignDetailPage")),
);
const AddOrEditCustomersPage = Loadable(
  lazy(() => import("pages/admin/customers/AddCustomerPage")),
);
const AddOrEditUserPage = Loadable(
  lazy(() => import("pages/admin/customers/AddOrEditUserPage")),
);
const CampaignOrdersPage = Loadable(
  lazy(() => import("pages/CampaignOrdersPage")),
);
const CampaignOrderDetailsPage = Loadable(
  lazy(() => import("pages/CampaignOrderDetailsPage")),
);
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));

const QuotationPage = Loadable(lazy(() => import("../pages/QuotationPage")));

function Router() {
  const { enabled: isDashboardEnabled } = useFeatureFlag("dashboard");
  const { enabled: isMaintenancePageEnabled } = useFeatureFlag("maintenance");
  const { isBackendHealthy } = useBackendHealthService(
    isMaintenancePageEnabled,
  );

  if (isMaintenancePageEnabled) {
    if (isBackendHealthy == null) return <Loading />;

    if (!isBackendHealthy)
      return useRoutes([
        {
          path: "*",
          element: <Navigate replace to="/maintenance" />,
        },
        {
          path: "/maintenance",
          element: <MaintenancePage />,
        },
      ]);
  }

  return useRoutes([
    {
      path: "redesign",
      element: (
        <AuthGuard>
          <LogoutIdleTimer>
            <Outlet />
          </LogoutIdleTimer>
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              replace
              to={
                isDashboardEnabled
                  ? "/redesign/dashboard"
                  : "/redesign/campaigns"
              }
            />
          ),
          index: true,
        },
        { path: "dashboard", element: <RedesignedDashboardPage /> },
        {
          path: "campaigns",
          children: [
            { element: <RedesignedCampaignsPage />, index: true },
            {
              path: ":id",
              element: <RedesignedCampaignDetailsPage />,
            },
            {
              path: ":id/edit",
              element: <RedesignedCreateOrEditCampaignPage isEdit />,
            },
            {
              path: "create",
              element: <RedesignedCreateOrEditCampaignPage />,
            },
            {
              path: "applications",
              element: <RedesignedCampaignApplicationsPage />,
            },
            {
              path: "orders",
              element: <RedesignedCampaignOrdersPage />,
            },
            {
              path: "orders/:id",
              element: <RedesignedCampaignOrderDetailsPage />,
            },
          ],
        },
        {
          path: "customers",
          element: <PermissionGuard requiredPermission="write:user" />,
          children: [
            { element: <RedesignedCustomersPage />, index: true },
            {
              path: ":id",
              element: <RedesignedCustomerDetailsPage />,
            },
          ],
        },
        { path: "profile", element: <RedesignedProfilePage /> },
        {
          path: "huk/gs",
          children: [
            { element: <RedesignedHukCampaignsPage />, index: true },
            {
              path: ":id",
              element: <RedesignedGSDetailsPage />,
            },
          ],
        },
        {
          path: "quotation-form",
          element: (
            <FeatureFlagGuard flag="quotations">
              <PermissionGuard requiredPermission="create:quotation">
                <RedesignedQuotationPage />
              </PermissionGuard>
            </FeatureFlagGuard>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <LogoutIdleTimer>
            <AppBarLayout />
          </LogoutIdleTimer>
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              replace
              to={isDashboardEnabled ? "/dashboard" : "/campaigns"}
            />
          ),
          index: true,
        },
        { path: "/campaigns", element: <Campaigns /> },
        { path: "/huk/gs", element: <HukCampaignsPage /> },
        { path: "/campaigns/create", element: <CreateAndEditCampaignPage /> },
        {
          path: "/campaigns/:campaignId/edit",
          element: <CreateAndEditCampaignPage isEdit />,
        },
        {
          path: "/campaigns/:id",
          element: <CampaignDetailPage />,
        },
        {
          path: "/huk/gs/:id/",
          element: <GsDetailPage />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },

        {
          path: "/quotation-form",
          element: (
            <FeatureFlagGuard flag="quotations">
              <PermissionGuard requiredPermission="create:quotation">
                <QuotationPage />
              </PermissionGuard>
            </FeatureFlagGuard>
          ),
        },
        {
          path: "/campaigns/orders",
          element: <CampaignOrdersPage />,
        },
        {
          path: "/campaigns/orders/:id",
          element: <CampaignOrderDetailsPage />,
        },
        {
          path: "/admin",
          element: (
            <RoleGuard allowedRoles={["woop_admin", "creator", "support"]} />
          ),
          children: [
            {
              path: "destinations",
              element: <Destinations />,
            },
          ],
        },
        {
          path: "/customers",
          element: <PermissionGuard requiredPermission="write:user" />,
          children: [
            {
              path: "",
              element: <Customers />,
              index: true,
            },

            { path: "add", element: <AddOrEditCustomersPage /> },
            {
              path: ":orgId/edit",
              element: <AddOrEditCustomersPage isEdit />,
            },
            {
              path: ":orgId/",
              element: <CustomerDetails />,
            },
            {
              path: ":orgId/new",
              element: <AddOrEditUserPage />,
            },
            {
              path: ":orgId/users/:userId/edit",
              element: <AddOrEditUserPage isEdit />,
            },
          ],
        },
      ],
    },
    { path: "/callback", element: <LoginCallback /> },
    { path: "/maintenance", element: <Navigate replace to="/" /> },
    {
      path: "*",
      element: <AppBarLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate replace to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate replace to="/404" /> },
  ]);
}

export default Router;
