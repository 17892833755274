import moment from "moment";
import type { ContactBoardEntryFragment } from "../../../generated/graphql";

export function formatDate(
  dateToFormat?: string | null | moment.Moment,
  withTime: boolean = true,
): string {
  if (dateToFormat == null) return "";
  const date = moment.isMoment(dateToFormat)
    ? dateToFormat
    : moment(dateToFormat);
  if (withTime) {
    return date.format("DD.MM.YYYY HH:mm");
  }
  return date.format("DD.MM.YYYY");
}

export function composeName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}

const QUESTION_TITLES_TO_FILTER = [
  "Cookies accepted",
  "Funnel Result",
  "Hier klicken und Datei hochladen",
  "Hier klicken und Lebenslauf hochladen",
  "UTM source",
  "UTM Source",
  "Wbraid",
  "Gclid",
  "Utm Id",
  "UTM Term",
  "UTM Medium",
  "utm_id",
  "UTM Campaign",
  "UTM Content",
  "sfnsn"
];

export function shouldDisplayQuestion(questionTitle: string): boolean {
  return (
    !QUESTION_TITLES_TO_FILTER.includes(questionTitle) &&
    !questionTitle.includes("Datenschutzbestimmungen")
  );
}

/**
 * Tries to find the URL of the CV in the answers
 */
export function findCvURL(
  answers: {
    question?: { title?: string | null } | null;
    value?: string | null;
  }[],
): string | null {
  return (
    answers.find((answer) =>
      /Hier klicken und \w+ hochladen/.test(answer.question?.title ?? ""),
    )?.value ?? null
  );
}
