import "@fontsource-variable/inter";
import CssBaseline from "@mui/material/CssBaseline";
import { SearchContextProvider } from "components/campaigns/filter/SearchContext";
import { Toaster } from "components/ui/Toaster";
import { ConfirmationProvider } from "contexts/redesign/ConfirmationProvider";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { ConfirmationDialogProvider } from "./contexts/ConfirmationDialogProvider";
import Router from "./router/router";

function App() {
  return (
    <ConfirmationProvider>
      <ConfirmationDialogProvider>
        <HelmetProvider>
          <CssBaseline />
          <SearchContextProvider>
            <Router />
            <Toaster />
          </SearchContextProvider>
        </HelmetProvider>
      </ConfirmationDialogProvider>
    </ConfirmationProvider>
  );
}

export default App;
